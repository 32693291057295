import React from "react";
import CustomNavbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import FooterData from "../../components/Footer/FooterData";
import PrototypeFooter from "../../components/Footer/PrototypeFooter";
import Partner from './../../components/Partner/Partner';

const PartnerForm = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="Img-02.jpg"
        Ptitle="Partner With Us"
        Pdescription="Congrats! We are glad to see that you are interested in using Movebot as courier service provider to deliver all items smart, easily track all deliveries and spend less resources(e.g money, time and energy) on items transportation. Kindly complete this form and submit so that we can meet you and discuss how to become our courier partner."
      />
      <Partner />
      <PrototypeFooter FooterData={FooterData} />
    </div>
  );
};
export default PartnerForm;
